import 'cookieconsent';
import '../stylesheets/cookieconsent-legacy.scss';

interface ICookieContent {
  header?: string;
  message?: string;
  dismiss?: string;
  allow?: string;
  deny?: string;
  link?: string;
  href?: string;
  close?: string;
  target?: string;
  policy?: string;
}

interface IWindowLegacyCookieData {
  href?: string;
  message?: string;
  dismiss?: string;
  link?: string;
}

interface IWindowWithLegacyCookieData extends Window {
  legacyCookieData?: IWindowLegacyCookieData;
  cookieconsent: any;
}

function hideCookieNotice() {
  // allows us to override the test env check and show the cookie notice.
  if (window?.location?.search === '?show_cookies_notice=true') return false;

  // don't show the notice in test env
  if ((window as any)?.railsEnv === 'test') return true;

  // don't show the notice on login endpoints
  const excludedPathNames = ['login', 'admin', 'platform', 'extensions'];
  const pathName = window.location.pathname;
  if (excludedPathNames.includes(pathName.split('/')[1])) return true;

  return false;
}

document.addEventListener('turbolinks:load', () => {
  if (hideCookieNotice()) return;

  const windowWithCookieData = window as unknown as IWindowWithLegacyCookieData;

  const cookieData: IWindowLegacyCookieData = windowWithCookieData.legacyCookieData || {};
  const cookieContent: ICookieContent = {
    dismiss: cookieData.dismiss || 'Got it!',
    href: cookieData.href || '/cookie-policy',
    link: cookieData.link || 'Learn More',
    message: cookieData.message || 'We use cookies to ensure you get the best experience on our website.',
  };

  windowWithCookieData.cookieconsent.initialise({
    content: cookieContent,
    position: 'bottom',
  });
});
